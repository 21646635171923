import * as React from "react";
import { Link } from "gatsby";
import {
  Container,
  Form,
  FormControl,
  InputGroup,
  Button,
  FormLabel,
} from "react-bootstrap";
import Layout from "../components/Layout";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
export default function UserRegistrationPage() {
  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-5 col-lg-5 col-md-12 pt-3 mx-auto text-center">
              <h1 className="fs-48 fx-blod mb-5">Register as new user</h1>
              <img src="../img/influencer-register.svg" alt="register" />
              <h5 className="fw-bold fs-36 mt-4">Register</h5>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 pb-5 mx-auto text-center">
              <Form className="text-start my-5">
                <FormLabel className="fs-13 bogo-par">Mobile number</FormLabel>
                <InputGroup className="mb-4">
                  <PhoneInput
                    country="ae"
                    enableAreaCodes={true}
                  />
                  <FormControl
                    type="tel"
                    aria-label="Text input with dropdown button"
                  />
                </InputGroup>
                <Button
                  variant="primary"
                  className="w-100 fs-14 fw-bold h-52"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
              <p className="mb-0">Already have an account?</p>
              <Link to="/user-login" className="text-dark text-decoration-none">
                <span className="primary-color fw-bold">Sign In </span>instead
              </Link>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
